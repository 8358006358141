<template>
  <div v-if="!isLoading && options.length > 1" class="flex justify-end mx-auto px-2 sm:px-4 lg:px-8 py-2 font-proxima">
    <div class="grid sm:flex sm:gap-4">
      <div class="min-w-[300px]">
        <FormKit
            type="dropdown"
            name="locations"
            label=""
            outer-class="!mb-0"
            selection-class="pl-2"
            :options="options"
            v-model="location"
            :disabled="loadingChangeLocation"
        >
          <template #option="{ option }">
            <div class="py-2 !font-proxima !text-base">
              <b>{{ option.locationName }}</b>
              <p>{{ option.licenseTypeDescription !== '' ? option.licenseTypeDescription : `Type ${option.type}`}}</p>
            </div>
          </template>
        </FormKit>
        <div v-if="loadingChangeLocation">
          <p class="text-sm text-red-500">Changing location, please wait...</p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isLoading && options.length === 0" class="pb-8 lg:pb-10"></div>
  <loading-overlay :show="loadingChangeLocation.value" />
</template>
<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import {useRuntimeConfig} from "#imports";

const userStore = useUserStore();
const {data, isLoading} = useQuery({
  queryKey: ['user-locations'],
  queryFn: () => userStore.getUserLocations()
})
const useSession = useSessionStore();

const options = computed(() => {
  return data.value?.data?.map((location: any) => {
    return {
      label: location.apptDesc,
      value: `${location.locationId}-${location.licenseTypeId}`,
      type: parseInt(location.licenseTypeId),
      apptDesc: location.apptDesc,
      locationName: location.locationName,
      licenseTypeDescription: location.licenseTypeDescription,
    }
  }).sort((a: any, b: any) => {
    // First sort by locationName alphabetically
    if (a.locationName < b.locationName) return -1;
    if (a.locationName > b.locationName) return 1;
    // If locationName is the same, sort by type
    return a.type - b.type;
  });
});

const config = useRuntimeConfig();


const location = ref(`${userStore.currentUser.licensedLocationId}-${userStore.currentUser.licenseTypeId}`)
const loadingChangeLocation = ref(false);
watch(location, () => {
  loadingChangeLocation.value = true;
  const [locationId, licenseTypeId] = location.value.split('-');
  const locationData = data.value.data.find((location: any) => location.locationId === parseInt(locationId) && location.licenseTypeId === parseInt(licenseTypeId));

  $fetch(`${config.public.legacyApiBase}/legacy/auth/change-location`, {
    method: 'post',
    ...globalFetchOptions(),
    body: {
      "userId": userStore.currentUser.profile?.userId,
      "moduleId": locationData?.moduleId,
      "locationId": locationData?.locationId,
      "licensedLocationId": locationData?.licensedLocationId,
      "roleId": locationData?.roleId,
      "licenseTypeId": locationData?.licenseTypeId
    }
  }).then(async (response: any) => {
    setUserSession(`${response.data.token}`);

    useSession.resetTimer();
    useSession.startCountdownTimer();
    await userStore.getCurrentUser();

    // refresh the page
    localStorage.setItem('userUpdate', 'yes');
    window.location.reload();
  }).catch((error) => {
    toastError('Error changing location', error?.response?._data?.error.message);
  }).finally(() => {
    loadingChangeLocation.value = false;
  });
})
</script>
